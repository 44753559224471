/* normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(./assets/fonts/OpenSans-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/OpenSans-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url(./assets/fonts/OpenSans-SemiBold.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url(./assets/fonts/OpenSans-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url(./assets/fonts/OpenSans-ExtraBold.woff2) format('woff2');
}


/* italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url(./assets/fonts/OpenSans-LightItalic.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url(./assets/fonts/OpenSans-Italic.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url(./assets/fonts/OpenSans-SemiBoldItalic.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url(./assets/fonts/OpenSans-BoldItalic.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url(./assets/fonts/OpenSans-ExtraBoldItalic.woff2) format('woff2');
}

